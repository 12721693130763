.object-fit-cover{
  object-fit: cover;
  object-position: center;
}
.component-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bg-light-t{
  background-color: #f8f9faa6;
}
.card.p-0.m-3{
  width:300px;
}
#in8wu{
  width:87%;
}
#i1q9yp{
  width:87%;
}

.card-img-top.rounded-circle{
  width:150px;
  height:150px;
}
.card-text{
  text-align:center;
}
.dormak-templete{
  .card-text-header{
    font-family:Arial Black, Gadget, sans-serif;
    font-size:17px;
    color:white;
    text-align:center;
  }

  .cards.pt-0.pb-0.justify-content-center.flex-wrap{
    padding-top:0px;
    padding-right:0px;
    padding-bottom:58px;
    padding-left:0px;
  }
  .card.p-1.m-3{
    background-color:rgb(255, 102, 0);
  }
  .card{
    background-color:red;
  }
  .card-text{
    color:rgb(255, 255, 255);
  }
  .card-text.card-text-header{
    font-family:"Arial Black", Gadget, sans-serif;
    font-size:17px;
    margin-top:20px;
    margin-right:0px;
    margin-bottom:16px;
    margin-left:0px;
  }
  .px-4{
    width:100%;
  }
  .card-text.blackButton{
    background-color:black;
    margin-top:auto;
    margin-right:auto;
    margin-bottom:auto;
    margin-left:auto;
    width:70%;
    padding-top:10px;
    padding-right:0px;
    padding-bottom:10px;
    padding-left:0px;
    color:white;
    font-family:"Arial Black", Gadget, sans-serif;
    font-size:14px;
  }
  .card.p-0.m-3{
    background-color:#FF7700;
  }
  .card.p-0.m-3.dmark-card{
    padding-top:0px;
    padding-right:0px;
    padding-bottom:0px;
    padding-left:0px;
  }
  .card.p-0.m-3.domark-card-black{
    background-color:black;
  }
  #iia68{
    color:black;
    width:157px;
    height:86px;
  }
  .mx-auto{
    margin-top:10px;
    margin-right:0px;
    margin-bottom:10px;
    margin-left:0px;
  }
  .card-link{
    color:white;
    font-family:Arial, Helvetica, sans-serif;
    text-align:center;
    font-weight:600;
    text-decoration: none;
  }
  .blackButton.mx-auto{
    padding-top:10px;
    padding-right:0px;
    padding-bottom:10px;
    padding-left:0px;
    width:80%;

    background-color:#1a1716;
  }
  .card{
    padding:0 0  0;
    margin:16px 16px 37px 16px;
    height:350px;
  }
  .card.p-0.m-3.domark-input-cart{
    background-color:rgb(57, 57, 57);
  }
  .form-group{
    width:80%;
  }
  .form-group.mx-auto{
    text-align:center;
  }
  .m-0{
    font-size:17px;
    font-family:"Arial Black", Gadget, sans-serif;
    text-align:center;
    color:white;
  }
  #iaejf{
    font-size:17px;
    font-family:"Arial Black", Gadget, sans-serif;
    color:white;
    text-align:center;
  }
  #i1s2j{
    text-align:center;
    color:white;
    font-family:"Arial Black", Gadget, sans-serif;
  }
  .form-text.text-muted{
    color: white!important;
  }
  .btn.btn-outline-primary{
    width: 240px;
    padding: 13px;
    display:block;
    float:none;
    position:relative;
    left:calc(50% - 120px);
    right:0px;
    background-color:#1a1716;
    border-top-width:1px;
    border-right-width:1px;
    border-bottom-width:1px;
    border-left-width:1px;
    border-top-style:solid;
    border-right-style:solid;
    border-bottom-style:solid;
    border-left-style:solid;
    border-top-color:#1a1716;
    border-right-color:#1a1716;
    border-bottom-color:#1a1716;
    border-left-color:#1a1716;
    border-image-source:initial;
    border-image-slice:initial;
    border-image-width:initial;
    border-image-outset:initial;
    border-image-repeat:initial;
    color:white;
    font-family:"Arial Black", Gadget, sans-serif;
    font-size:17px;
    margin-top:10px;
    margin-right:0px;
    margin-bottom:10px;
    margin-left:0px;
  }
  .btn.btn-outline-primary.mx-auto{
    width:200px;
    text-align:center;
    position:relative;
    left:50%;
    transform:rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1);
  }
  .cards.pt-5.pb-5.justify-content-center.flex-wrap{
    border-top-width:initial;
    border-right-width:initial;
    border-bottom-width:initial;
    border-left-width:initial;
    border-top-style:solid;
    border-right-style:solid;
    border-bottom-style:solid;
    border-left-style:solid;
    border-top-color:black;
    border-right-color:black;
    border-bottom-color:black;
    border-left-color:black;
    border-image-source:initial;
    border-image-slice:initial;
    border-image-width:initial;
    border-image-outset:initial;
    border-image-repeat:initial;
  }
  .btn.btn-outline-primary:active,
  .btn.btn-outline-primary:hover{
    background-color: #1a1716!important;
    border-color: #1a1716!important;
  }
  #iu4yw{
    position:relative;
    float:right;
  }
  #i8ocu{
    position:relative;
    float:right;
  }
  .card.p-0.m-3.colun-6{
    width:45%;
  }
  .card.p-0.m-3.colun-12{
    width:100%;
  }
  .card-text.px-4{
    font-family:Arial, Helvetica, sans-serif;
    font-size:13px;
  }
  .dormak-templete.cards.pt-5.pb-5.justify-content-center.flex-wrap{
    margin-top:0px;
    margin-right:0px;
    margin-bottom:0px;
    margin-left:0px;
    width:auto;
    max-width:1400px;
  }

}

